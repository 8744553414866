export default [
  {
    title: 'Submission',
    route: 'submission-list',
    icon: 'FileTextIcon',
  },
  {
    title: 'Renewal',
    route: 'renewal-list',
    icon: 'ClockIcon',
  },
  {
    title: 'Transaction',
    route: 'transaction-list',
    icon: 'ShoppingCartIcon',
  },
  {
    title: 'Users',
    route: 'users-list',
    icon: 'UsersIcon',
  },
]
